import './App.css';
import {useEffect, useState} from "react";
import ProblemService from "./services/ProblemService";
import {DataGrid} from '@mui/x-data-grid';

function App() {
    const [problems, setProblems] = useState([]);
    const [pageSize, setPageSize] = useState(100);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        const fetchProblems = async () => {
            const response = await ProblemService.getAllProblems();
            setProblems(response.data);
            // const response = await ProblemService.getPage(0, pageSize);
            // setProblems(response.data.content);
            // setTotalPages(response.data.totalPages);
            // const response = await ProblemService.getAllProblems();
        };
        fetchProblems().catch(console.error);
    }, []);

    const columns = [
        {field: "informaticsProblemId", headerName: "ID задачи на Informatics", flex: 1},
        {field: "problemName", headerName: "Название задачи", flex: 1.5},
        {field: "ejudgeContestId", headerName: "ID контеста в Ejudge", flex: 0.5},
        {field: "ejudgeProblemLetter", headerName: "Буква задачи в Ejudge", flex: 0.5},
        {field: "polygonLink", headerName: "Ссылка из Polygon", flex: 3}
    ];

    const changePage = async (page) => {
        const response = await ProblemService.getPage(page, pageSize);
        setProblems(response.data.content);
        setTotalPages(response.data.totalPages);
    };


    const handleEvent = () => {
        document.execCommand('copy');
    };

    document.addEventListener('copy', function(e){
        console.log(e.path[2].ariaRowIndex - 2)
        if (problems[e?.path[2]?.ariaRowIndex - 2]?.polygonLink !== null && problems[e?.path[2]?.ariaRowIndex - 2]?.polygonLink !== undefined) {
            e.clipboardData.setData('text/plain', problems[e.path[2].ariaRowIndex - 2].polygonLink);
            e.preventDefault();

            console.log(problems[e.path[2].ariaRowIndex - 2].polygonLink);
            document.getElementById('modal').style.visibility="visible";
            setTimeout(() => {
                document.getElementById('modal').style.visibility="hidden";
            }, 2000);
        }
    });

    return (
        <div className="App">
            <DataGrid columns={columns}
                      rows={problems}
                      pageSize={pageSize}
                      rowsPerPageOptions={[pageSize]}
                      pagination={true}
                      autoHeight={true}
                      onRowClick={handleEvent}
            />
            <div id="modal" className={"modal"}>Copied!</div>
        </div>
    );
}

export default App;
