import axios from 'axios';

export default class ProblemService {
    static async getAllProblems() {
        return await axios.get("/api/problems/");
    }

    static async getPage(pageId, pageSize) {
        return await axios.get("/api/problems/page", {
            params: {pageId, pageSize}
        });
    }
}